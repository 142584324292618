/*
 * Copyright 2020 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.form-container {
  padding: 20px;
  display: flex;
  width:   60%;
}

.purge-settings-fields {
  display:        flex;
  flex-direction: column;
}

.server-configuration-container {
  display: flex;
  width:   100%;
}

.left-panel {
  background-color: $white;
  margin-right:     10px;
  padding-right:    10px;
  width:            20%;

  ul {
    list-style:    none;
    padding-right: 30px;
    padding-left:  20px;

    li {
      cursor:        pointer;
      border-bottom: 1px solid $border-color;
      padding:       10px 10px 10px 15px;
    }

    .active {
      border-bottom: 2px solid $go-primary;
      color:         $go-primary;
    }
  }
}

.right-panel {
  background-color: $white;
  width:            100%;
}

.form-header {
  border-bottom:   1px solid $border-color;
  display:         flex;
  flex-direction:  row;
  justify-content: space-between;
}

.buttons {
  margin-top: 10px;
  float:      right;
}

.form-fields {
  border-bottom: 1px solid $border-color;
  padding-top:   20px;
}

.deleteIcon {
  margin: 10px 0;
}

.admin-mail {
  width:   100%;
  display: flex;

  button {
    margin-top: 26px;
  }

  .admin-mail-input {
    width: 40%;
  }
}

@mixin test-connection-button-icon($type, $colors: $icon-light-color, $size: 5px) {
  @include icon-before($type: $type, $color: $colors, $size: 15px, $margin: 0) {
    display:      inline-block;
    margin-right: $size;
    @content
  }
  font-size: 11px;
}

.test-connection-success {
  @include test-connection-button-icon($type: $fa-var-check);
}

.test-connection-failure {
  @include test-connection-button-icon($type: $fa-var-exclamation);
}
