/*
 * Copyright 2020 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$build_details_z_index:           2;
$building_bg_color:               #ffeda0;
$enable_count_bg_color:           #50e3c2;
$disable_count_bg_color:          #ee6161;
$disabled-text-color:             #999;
$icon-hover-color:                #000;
$dropdown-button-content-z-index: 5;
.agent-status {
  @include icon-after($type: $fa-var-caret-down, $color: $icon-color);
  position:        relative;
  text-decoration: none;
  color:           $link-color;
  display:         block;

  &:after {
    font-size: 10px;
  }
}

.build-details {
  position:      absolute;
  display:       none;
  background:    $white;
  list-style:    none;
  padding:       0;
  margin:        0;
  border:        1px solid $border-color;
  border-radius: 3px;
  z-index:       $build_details_z_index;

  &.show {
    display: block;
  }
}

.build-details li {
  border-bottom: 1px dotted $border-color;
  padding:       4px 10px;
}

.build-details a {
  text-decoration: none;
  color:           $link-color;
}

.hostname {
  a {
    text-decoration: none;
    color:           $link-color;
  }
}

.agent-checkbox {
  margin:  0;
  padding: 0;

  input[type="checkbox"] { // sass-lint:disable-line no-qualifying-elements
    width:      12px;
    height:     12px;
    margin-top: -5px;
  }
}

.agents-table {
  padding: 0;

  table {
    height: 100%;
  }

  table tbody td {
    padding:        0;
    display:        table-cell;
    vertical-align: middle;
    height:         24px;
  }

  table tbody .table-cell {
    padding: 10px;
    height:  100%;
  }
}

.building {
  background: $building_bg_color;
}

.disabled-agent {
  color:      $disabled-text-color;
  font-style: italic;
}

.header-panel {
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  margin-bottom:   10px;

  ul li label {
    font-size: 14px;
  }

  .count {
    font-size:   16px;
    font-weight: 600;
    display:     block;
    margin-top:  -2px;
  }

  .enabled {
    color: $enable_count_bg_color;
  }

  .disabled {
    color: $disable_count_bg_color;
  }

  .left-container {
    display:     flex;
    align-items: center;
  }

  .left-container ul {
    margin-left: 20px;
  }
}

.dropdown-content {
  background:      $white;
  position:        absolute;
  border-radius:   $global-border-radius;
  padding:         20px;
  box-shadow:      0 2px 4px $box-shadow-color;
  width:           250px;
  right:           0;
  display:         flex;
  justify-content: center;
  flex-direction:  column;
  z-index:         $dropdown-button-content-z-index;

  li {
    margin-bottom: 10px;
    border-bottom: 1px dotted $border-color;

    div {
      margin-bottom: 0;
    }
  }
}

.dropdown-content-footer {
  align-self: flex-start;
}

.quick-add li {
  border-bottom: none;
}

.agent-analytics a {
  @include icon-before($type: $fa-var-bar-chart);
  padding:   0;
  font-size: 14px;
  color:     $icon-color;
  cursor:    pointer;

  &:hover {
    color: $icon-hover-color;
  }
}

.environment-info{
  display: flex;
  justify-content: space-between;

  > div {
   margin-bottom: 0;
  }
}
