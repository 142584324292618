/*
 * Copyright 2020 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../../global/common";

$revoked_text_color: #e94c35;
.description {
  display:       inline-block;
  max-width:     250px;
  min-width:     250px;
  overflow:      hidden;
  white-space:   nowrap;
  text-overflow: ellipsis;
}

.revoked {
  color:       $revoked_text_color;
  font-weight: 600;
}

.access-token-search-box {
  display:         flex;
  justify-content: flex-end;
  margin-bottom:   10px;
}

.personal-access-token-container {
  .active-tokens-table thead {
    th {
      width: 25%;
    }

    th:nth-child(1) {
      width: 40%;
    }

    th:last-child {
      width: 10%;
    }
  }

  .revoked-tokens-table thead {
    th {
      width: 15%;
    }

    th:first-child {
      width: 20%;
    }

    th:last-child {
      width: 20%;
    }
  }
}

.admin-access-token-container {
  .active-tokens-table thead {
    th {
      width: 15%;
    }

    th:nth-child(1) {
      width: 20%;
    }

    th:nth-child(2) {
      width: 40%;
    }

    th:last-child {
      width: 10%;
    }
  }

  .revoked-tokens-table thead {
    th {
      width: 15%;
    }

    th:nth-child(1) {
      width: 10%;
    }

    th:nth-child(2) {
      width: 20%;
    }

    th:nth-child(5) {
      width: 10%;
    }
  }
}

.spinner-container {
  height: 200px;
}

.tips {
  background: $white;
  padding: 20px;
}
