/*
 * Copyright 2020 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

$global-border-color: $line-color;
$global-border-radius: 3px;
$form-element-height: 35px;

$form-help-text-color: #999;
$form-placeholder-color: #bbb;

$form-control-color: #495057;
$form-control-disabled-color: #d4d7da;
$form-control-border-color: #80bdff;
$form-control-background-color: #fff;
$form-failed-color: $failed;
$form-failed-field-background-color: lighten($form-failed-color, 35%);

.form-header {
  background: $form-header-bg;
  margin:     -20px -30px $global-margin-bottom -30px;
  padding:    20px 30px 0;
}

.form-group {
  list-style-type: none;
  font-size:       14px;
  margin-bottom:   20px;
}

.form-disabled {
  .form-label {
    color: $form-help-text-color;
  }

  .form-control, .form-check-input {
    cursor: not-allowed;
  }
}

.form-label {
  display:       block;
  margin-bottom: 5px;
}

.form-label-required {
  color: $form-failed-color;
}

.form-control {
  display:          block;
  height:           $form-element-height;
  line-height:      $form-element-height;
  padding:          0 10px;
  margin-right:     5px;
  font-size:        14px;
  background-color: $form-control-background-color;
  background-clip:  padding-box;
  border:           1px solid $global-border-color;
  border-radius:    $global-border-radius;
  transition:       border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width:            250px;
  box-sizing:       border-box;

  &:focus {
    color:            $form-control-color;
    background-color: $form-control-background-color;
    border-color:     $form-control-border-color;
    outline:          0;
    box-shadow:       0 0 0 0.2rem $form-control-border-color;
  }

  &.inline {
    display: inline-block;
  }
}

.form-check {
  position:      relative;
  display:       block;
  padding-left:  15px;
  margin-bottom: 10px;

  .form-check-input {
    @include size(16px, 16px);
    position:    absolute;
    margin-top:  3px;
    margin-left: -15px;
  }

  .form-label {
    display:     inline-block;
    margin-left: 5px;
    cursor:      pointer;
  }
}

.radio-field {
  list-style-type: none;
  font-size:       14px;
  margin-bottom:   5px;

  .form-help {
    display:    block;
    font-size:  12px;
    margin-left: 15px;
    color:      $form-help-text-color;
  }
}

.radio-label {
  display:     inline-block;
  margin-left: 5px;
  cursor:      pointer;
}

.inline-radio-btns {
  display:         inline-flex;
  justify-content: space-between;
  width:           100%;
}

select.form-control { //sass-lint:disable-line no-qualifying-elements
  width: 270px;
}

input:required {
  box-shadow: none; // in firefox, required fields are outlined in red
}

input::placeholder {
  color: $form-placeholder-color;
  font-style: italic;
}

input[type="checkbox"].form-control { //sass-lint:disable-line no-qualifying-elements
  width: 35px;

  &:focus {
    outline:    0;
    box-shadow: none;
  }
}

.form-input-reset {
  margin: 5px 0 0;
  display: inline-block;
}

.text-area {
  height:      auto;
  line-height: 20px;
}

.form-control:disabled, .form-control[readonly] {
  background-color: $form-control-disabled-color;
  opacity:          1;
}

.form-help {
  display:    inline-block;
  font-size:  12px;
  margin-top: 5px;
  color:      $form-help-text-color;
}

.form-error-text {
  display:     block;
  font-size:   12px;
  margin-top:  5px;
  font-weight: bolder;
}

.form-has-error {
  color: $form-failed-color;

  .form-control {
    border-color:     $form-failed-color;
    background-color: $form-failed-field-background-color;
  }
}

.form {
  list-style-type: none;
  margin:          0 0 20px;
  padding:         0;

  &.last {
    margin-bottom: 0;
  }
}

.form-responsive {
  display:         flex;
  flex-wrap:       wrap;
  justify-content: flex-start;

  .form-group {
    width:   100%;
    padding: 0 15px;
    @media(min-width: $screen-sm) {
      width:     50%;
      max-width: 300px;
    }
    @media(min-width: $screen-md) {
      width: 50%;
    }
    @media(min-width: $screen-lg) {
      width: 33.3%;

    }
    @media(min-width: $screen-lg) {
      width: 300px;

    }
  }
}

.search-box-wrapper {
  @include icon-before($type: $fa-var-search, $size: 14px, $top: 5px, $color: $icon-color) {
    position: absolute;
    left:     10px;
  }

  display:  inline-block;
  position: relative;

  .search-box-input {
    padding: 0 10px 0 40px;

    &::placeholder {
      color:     $icon-color;
      font-size: 12px;
      opacity:   0.8;
    }
  }
}

.form-group-text-field-with-button {
  display: flex;
  padding: 0;
  width:   auto;

  .form-control {
    border-radius: $global-border-radius 0 0 $global-border-radius;
  }

  .quick-add-button {
    @include button($background-color: $btn-secondary);
    border-radius: 0 $global-border-radius $global-border-radius 0;
  }

  .search-button {
    @include button($background-color: $btn-secondary);
    border-radius: 0 $global-border-radius $global-border-radius 0;
  }
}

.form-compact {
  .form-group {
    margin-bottom: 10px;
  }

  .form-check {
    margin-bottom: 0;
  }

  .form-help {
    display: block;
  }
}

.input-small {
  width: 250px;
}

.input-medium {
  width: 300px;
}

.input-match-parent {
  width: 100%;
}

.textarea-fixed {
  resize: none;
}

.hide-password {
  @include icon-after($type: $fa-var-eye-slash, $size: 14px, $top: 1px, $color: $icon-color) {
    right: 30px;
  }

  input {
    padding-right: 25px;
  }
}

.show-password {
  @include icon-after($type: $fa-var-eye, $size: 14px, $top: 1px, $color: $icon-color) {
    right: 30px;
  }

  input {
    padding-right: 25px;
  }
}
